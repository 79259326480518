import React, { useEffect, useState, useRef } from "react";

// Styles
import "../styles/globals.css";
import "../styles/home.css";
import "../styles/comingsoon.css";
import "../styles/berahouses.css";
import MetamaskImg from "../assets/img/metamask-icon.png";
import { MdClose } from "react-icons/md";

const ConnectWalletModal = (props) => {
  return (
    <div className="connect-wallet-modal">
      <div className="connect-wallet-modal-box">
        <div className="cwm-header">
          <h3>Connect Wallet</h3>
          <div onClick={props.hideConnectWalletModal}>
            <MdClose />
          </div>
        </div>
        <div className="cwm-divider"></div>
        <div className="wallet-boxed-container">
          <div className="wallet-boxes">
            <h5>Metamask</h5>
            <img src={MetamaskImg} alt="MetamaskImg" />
          </div>
          <div className="wallet-boxes">
            <h5>Metamask</h5>
            <img src={MetamaskImg} alt="MetamaskImg" />
          </div>
          <div className="wallet-boxes">
            <h5>Metamask</h5>
            <img src={MetamaskImg} alt="MetamaskImg" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectWalletModal;
