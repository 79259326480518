import React, { createContext, useState } from "react";

export const AudioContext = createContext();

export const AudioProvider = (props) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <AudioContext.Provider value={{ isPlaying, togglePlay }}>
      {props.children}
    </AudioContext.Provider>
  );
};
