import React, { useEffect, useState, useContext } from "react";

// Styles
import "../styles/globals.css";
import "../styles/home.css";
import "../styles/comingsoon.css";
import "../styles/berahouses.css";
import "../styles/farms.css";
import "bootstrap/dist/css/bootstrap.min.css";
// Images
import Logo from "../assets/img/logo-1.png";
import BabyBeraImg from "../assets/img/desk.png";
import Logo2 from "../assets/img/logo-2.png";
import BTCIcon from "../assets/img/btc.png";
import USDCIcon from "../assets/img/usdc.png";
import ETHIcon from "../assets/img/eth.png";
import BeraEyesOpen from "../assets/img/aaa.png";
import BeraEyesClosed from "../assets/img/bbb.png";
import RightDoubleArrowIcon from "../assets/icons/right-double-arrow-icon.svg";

import MintL1Img from "../assets/img/m-l1-img.png";
import MintL2Img from "../assets/img/m-l2-img.png";
import MintL3Img from "../assets/img/m-l3-img.png";
import MintL4Img from "../assets/img/m-l4-img.png";
import MintL5Img from "../assets/img/m-l5-img.png";
import MintR1Img from "../assets/img/m-r1-img.png";
import MintR2Img from "../assets/img/m-r2-img.png";
import MintR3Img from "../assets/img/m-r3-img.png";
import MintR4Img from "../assets/img/m-r4-img.png";
import StakeVid from "../assets/videos/stake-vid.mp4";
import HomeBL1Img from "../assets/img/home-bl1.png";
import HomeBL2Img from "../assets/img/home-bl2.png";
import HomeFloatingBubble1Img from "../assets/img/home-floating-bubble-1.png";
import HomeFloatingBubble2Img from "../assets/img/home-floating-bubble-2.png";
import HomeFloatingBubble3Img from "../assets/img/home-floating-bubble-3.png";
import HomeFloatingBubble4Img from "../assets/img/home-floating-bubble-4.png";
import BUSDImg from "../assets/img/BUSD.png";
import MintIconDark from "../assets/icons/mint-dark.png";
import MintIconLight from "../assets/icons/mint-light.png";
import EGGImg from "../assets/img/EGG.png";
import BerahouseArrowImg from "../assets/img/berahouse-arrow.png";
import Modal from "react-bootstrap/Modal";
import { ThemeContext } from "../ThemeContext";

// Components
import gsap from "gsap";
import {
  Back,
  Power3,
  Power1,
  Power2,
  Power4,
  Linear,
  Expo,
  Circ,
} from "gsap/dist/gsap";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { Draggable } from "gsap/Draggable";
import { InertiaPlugin } from "gsap/InertiaPlugin";
import { SplitText } from "gsap/SplitText";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import $ from "jquery";
import {
  FaDiscord,
  FaMediumM,
  FaTwitter,
  FaPlay,
  FaMinus,
} from "react-icons/fa";
import { BsLockFill, BsDash } from "react-icons/bs";

import {
  MdKeyboardArrowUp,
  MdKeyboardArrowDown,
  MdArrowDropDown,
  MdNorthEast,
  MdShare,
  MdAdd,
} from "react-icons/md";

import BeraHouseCard from "../components/BeraHouseCard";
import FarmsCard from "../components/FarmsCard";
import ConnectWalletModal from "../components/ConnectWalletModal";
import APRModal from "../components/APRModal";
import { Form, Nav } from "react-bootstrap";
import Header from "../components/Header";

const MintNew = () => {
  const [showConnectWalletModal, setShowConnectWalletModal] = useState(false);
  const hideConnectWalletModalFun = () => setShowConnectWalletModal(false);
  const showConnectWalletModalFun = () => setShowConnectWalletModal(true);
  const { isDarkTheme, toggleTheme } = useContext(ThemeContext);

  const [showAPRModal, setShowAPRModal] = useState(false);
  const hideAPRModalFun = () => setShowAPRModal(false);
  const showAPRModalFun = () => setShowAPRModal(true);

  gsap.registerPlugin(
    Draggable,
    SplitText,
    InertiaPlugin,
    ScrollSmoother,
    ScrollTrigger
  );

  useEffect(() => {
    ScrollSmoother.create({
      smooth: 1.15,
      effects: true,
      smoothTouch: 0,
    });

    gsap.fromTo(
      ".home-floating-bubble-1",
      {
        y: "-1rem",
      },
      {
        y: "1rem",
        ease: Linear.easeInOut,
        duration: 2,
        repeat: -1,
        yoyo: true,
      }
    );
    gsap.fromTo(
      ".home-floating-bubble-2",
      {
        y: "0",
      },
      {
        y: "1rem",
        ease: Linear.easeInOut,
        duration: 2,
        repeat: -1,
        yoyo: true,
      }
    );
    gsap.fromTo(
      ".home-floating-bubble-3",
      {
        y: "2rem",
      },
      {
        y: "0",
        ease: Linear.easeInOut,
        duration: 2,
        repeat: -1,
        yoyo: true,
      }
    );
    gsap.fromTo(
      ".home-floating-bubble-4",
      {
        y: "1rem",
      },
      {
        y: "-1rem",
        ease: Linear.easeInOut,
        duration: 2,
        repeat: -1,
        yoyo: true,
      }
    );
    Draggable.create(".mint-l1-img, .mint-l2-img,.mint-r1-img, .mint-r2-img", {
      type: "rotation",
      edgeResistance: 1,
      bounds: ".farms-page",
      inertia: true,
      requestAnimationFrame,
    });

    Draggable.create(".mint-l3-img, .mint-l4-img, .mint-l5-img", {
      type: "x,y",
      edgeResistance: 1,
      bounds: ".farms-page",
      inertia: true,
      requestAnimationFrame,
    });

    var droppables = $(".cs-draggable-shapes");
    var dropArea = $("#dropArea");
    var overlapThreshold = "99%";
    Draggable.create(droppables, {
      bounds: ".farms-page",
      onDrag: function (e) {
        if (this.hitTest(dropArea, overlapThreshold)) {
          $(this.target).addClass("highlight");
        } else {
          $(this.target).removeClass("highlight");
        }
      },
      onDragEnd: function (e) {
        if (!$(this.target).hasClass("highlight")) {
          let CSShapesDrag = gsap.timeline();
          CSShapesDrag.to(this.target, 0.2, {
            x: 0,
            y: 0,
          });
        }
      },
    });

    let CSShapesAnimBottomRight = gsap.timeline();
    CSShapesAnimBottomRight.fromTo(
      ".home-br1-img",
      {
        y: "-125vh",
      },
      {
        ease: Back.easeInOut,
        duration: 1.5,
        y: "0",
        delay: 0.5,
      }
    ).fromTo(
      ".home-br2-img",
      {
        y: "-125vh",
      },
      {
        ease: Back.easeInOut,
        duration: 1.5,
        y: "0",
      },
      "<0.15"
    );
    let CSShapesAnimRight = gsap.timeline();
    CSShapesAnimRight.fromTo(
      ".home-r1-img",
      {
        y: "125vh",
      },
      {
        ease: Back.easeInOut,
        duration: 1.5,
        y: "0",
        delay: 0.5,
      }
    )
      .fromTo(
        ".home-r2-img",
        {
          y: "125vh",
        },
        {
          ease: Back.easeInOut,
          duration: 1.5,
          y: "0",
        },
        "<0.15"
      )
      .fromTo(
        ".home-r3-img",
        {
          y: "125vh",
        },
        {
          ease: Back.easeInOut,
          duration: 1.5,
          y: "0",
        },
        "<0.15"
      );
    let CSShapesAnimLeft = gsap.timeline();
    CSShapesAnimLeft.fromTo(
      ".home-l1-img",
      {
        y: "-150vh",
      },
      {
        ease: Back.easeInOut,
        duration: 1.5,
        y: "0",
        delay: 0.5,
      }
    ).fromTo(
      ".home-l2-img",
      {
        y: "-150vh",
      },
      {
        ease: Back.easeInOut,
        duration: 1.5,
        y: "0",
      },
      "<0.15"
    );
  }, []);
  return (
    <main className="babybera-app">
      <div className="farms-page">
        <div data-speed={1.2} className="mint-left-shapes-box">
          <img
            className="mint-l1-img cs-rotatable-shapes cs-shapes"
            src={MintL1Img}
            alt="MintLImg"
          />
          <img
            className="mint-l2-img cs-rotatable-shapes cs-shapes"
            src={MintL2Img}
            alt="MintLImg"
          />
          <img
            className="mint-l3-img cs-draggable-shapes cs-shapes"
            src={MintL3Img}
            alt="MintLImg"
          />
          <img
            className="mint-l4-img cs-draggable-shapes cs-shapes"
            src={MintL4Img}
            alt="MintLImg"
          />
          <img
            className="mint-l5-img cs-draggable-shapes cs-shapes"
            src={MintL5Img}
            alt="MintLImg"
          />
        </div>
        <div data-speed={1.3} className="mint-right-shapes-box">
          <img
            className="mint-r1-img cs-rotatable-shapes cs-shapes"
            src={MintR1Img}
            alt="MintLImg"
          />
          <img
            className="mint-r2-img cs-rotatable-shapes cs-shapes"
            src={MintR2Img}
            alt="MintLImg"
          />
          <img
            className="mint-r3-img cs-draggable-shapes cs-shapes"
            src={MintR3Img}
            alt="MintLImg"
          />
          <img
            className="mint-r4-img cs-draggable-shapes cs-shapes"
            src={MintR4Img}
            alt="MintLImg"
          />
        </div>
        <div className="berahouse-box">
          <div className="farms-top">
            <div className="farms-top-text">
              <h1>
                MINT{" "}
                <div>
                  {isDarkTheme ? (
                    <img src={MintIconLight} alt="" className="mint-icon-img" />
                  ) : (
                    <img src={MintIconDark} alt="" className="mint-icon-img" />
                  )}
                </div>
              </h1>
              <h2>mint babybera nfts</h2>
            </div>
          </div>
          <div className="mint-grid">
            <div className="mint-card">
              <video src={StakeVid} controls></video>

              <div className="plus-minus-box">
                <button className="minus-box">
                  <BsDash />
                </button>
                <p>0</p>
                <button className="plus-box">
                  <MdAdd />
                </button>
              </div>
              <button className="bb-btn-grd-fill bb-btn-grd-fill-lg">
                <span>Mint</span>
              </button>
              <button className="bb-btn-grd bb-btn-grd-lg">
                <span>
                  <i>CONNECT WALLET</i>
                  <BsLockFill />
                </span>
              </button>
            </div>
            <div className="mint-progress-box">
              <div className="mint-progress">
                <div className="inner-progress"></div>
              </div>
              <p>43 of 69 MINTED</p>
            </div>
          </div>
        </div>
      </div>
      <div id="dropArea"></div>
      <Modal
        centered
        show={showConnectWalletModal}
        onHide={hideConnectWalletModalFun}
      >
        <Modal.Body>
          <ConnectWalletModal
            hideConnectWalletModal={hideConnectWalletModalFun}
          />
        </Modal.Body>
      </Modal>

      <Modal centered show={showAPRModal} onHide={hideAPRModalFun}>
        <Modal.Body>
          <APRModal hideAPRModal={hideAPRModalFun} />
        </Modal.Body>
      </Modal>
    </main>
  );
};

export default MintNew;
