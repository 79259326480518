import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ThemeContext from "./ThemeContext";
import { AudioContext, AudioProvider } from "./AudioContext";
import Header from "./components/Header";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeContext>
    <AudioProvider>
      <App />
    </AudioProvider>
  </ThemeContext>
);

reportWebVitals();
