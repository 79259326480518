import React, { useEffect, useState, useRef, useContext } from "react";

// Styles
import "../styles/globals.css";
import "../styles/theme.css";
import "../styles/home.css";
import "../styles/comingsoon.css";
import "../styles/berahouses.css";
import "../styles/farms.css";
import "bootstrap/dist/css/bootstrap.min.css";
// Images
import Logo from "../assets/img/logo-1.png";
import BabyBeraImg from "../assets/img/desk.png";
import Logo2 from "../assets/img/logo-2.png";
import BTCIcon from "../assets/img/btc.png";
import USDCIcon from "../assets/img/usdc.png";
import ETHIcon from "../assets/img/eth.png";
import BeraEyesOpen from "../assets/img/aaa.png";
import BeraEyesClosed from "../assets/img/bbb.png";
import RightDoubleArrowIcon from "../assets/icons/right-double-arrow-icon.svg";

import FarmsL1Img from "../assets/img/f-l1-img.png";
import FarmsL2Img from "../assets/img/f-l2-img.png";
import FarmsL3Img from "../assets/img/m-r3-img.png";
import FarmsL4Img from "../assets/img/f-l3-img.png";
import FarmsR1Img from "../assets/img/f-r1-img.png";
import FarmsR2Img from "../assets/img/home-r1.png";
import FarmsR3Img from "../assets/img/f-r3-img.png";

import FarmIconDark from "../assets/icons/farm-dark.png";
import FarmIconLight from "../assets/icons/farm-light.png";
import HomeFloatingBubble1Img from "../assets/img/home-floating-bubble-1.png";
import HomeFloatingBubble2Img from "../assets/img/home-floating-bubble-2.png";
import HomeFloatingBubble3Img from "../assets/img/home-floating-bubble-3.png";
import HomeFloatingBubble4Img from "../assets/img/home-floating-bubble-4.png";
import BUSDImg from "../assets/img/BUSD.png";
import EGGImg from "../assets/img/EGG.png";
import BerahouseArrowImg from "../assets/img/berahouse-arrow.png";
import Modal from "react-bootstrap/Modal";
import { ThemeContext } from "../ThemeContext";

// Components
import gsap from "gsap";
import {
  Back,
  Power3,
  Power1,
  Power2,
  Power4,
  Linear,
  Expo,
  Circ,
} from "gsap/dist/gsap";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { Draggable } from "gsap/Draggable";
import { InertiaPlugin } from "gsap/InertiaPlugin";
import { SplitText } from "gsap/SplitText";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import $ from "jquery";
import { FaDiscord, FaMediumM, FaTwitter, FaPlay } from "react-icons/fa";
import { BsLockFill } from "react-icons/bs";

import {
  MdKeyboardArrowUp,
  MdKeyboardArrowDown,
  MdArrowDropDown,
  MdNorthEast,
  MdShare,
} from "react-icons/md";

import BeraHouseCard from "../components/BeraHouseCard";
import FarmsCard from "../components/FarmsCard";
import ConnectWalletModal from "../components/ConnectWalletModal";
import APRModal from "../components/APRModal";
import { Form, Nav } from "react-bootstrap";
import Header from "../components/Header";

const ComingSoon = () => {
  const [showConnectWalletModal, setShowConnectWalletModal] = useState(false);
  const hideConnectWalletModalFun = () => setShowConnectWalletModal(false);
  const showConnectWalletModalFun = () => setShowConnectWalletModal(true);
  const { isDarkTheme, toggleTheme } = useContext(ThemeContext);

  const [showAPRModal, setShowAPRModal] = useState(false);
  const hideAPRModalFun = () => setShowAPRModal(false);
  const showAPRModalFun = () => setShowAPRModal(true);

  gsap.registerPlugin(
    Draggable,
    SplitText,
    InertiaPlugin,
    ScrollSmoother,
    ScrollTrigger
  );

  useEffect(() => {
    ScrollSmoother.create({
      smooth: 1.15,
      effects: true,
      smoothTouch: 0,
    });

    gsap.fromTo(
      ".home-floating-bubble-1",
      {
        y: "-1rem",
      },
      {
        y: "1rem",
        ease: Linear.easeInOut,
        duration: 2,
        repeat: -1,
        yoyo: true,
      }
    );
    gsap.fromTo(
      ".home-floating-bubble-2",
      {
        y: "0",
      },
      {
        y: "1rem",
        ease: Linear.easeInOut,
        duration: 2,
        repeat: -1,
        yoyo: true,
      }
    );
    gsap.fromTo(
      ".home-floating-bubble-3",
      {
        y: "2rem",
      },
      {
        y: "0",
        ease: Linear.easeInOut,
        duration: 2,
        repeat: -1,
        yoyo: true,
      }
    );
    gsap.fromTo(
      ".home-floating-bubble-4",
      {
        y: "1rem",
      },
      {
        y: "-1rem",
        ease: Linear.easeInOut,
        duration: 2,
        repeat: -1,
        yoyo: true,
      }
    );
    Draggable.create(".farms-l1-img, .farms-l2-img, .farms-r1-img", {
      type: "rotation",
      edgeResistance: 1,
      bounds: ".farms-page",
      inertia: true,
      requestAnimationFrame,
    });

    Draggable.create(
      ".farms-l3-img, .farms-l4-img, .farms-r2-img, .farms-r3-img",
      {
        type: "x,y",
        edgeResistance: 1,
        bounds: ".farms-page",
        inertia: true,
        requestAnimationFrame,
      }
    );

    var droppables = $(".cs-draggable-shapes");
    var dropArea = $("#dropArea");
    var overlapThreshold = "99%";
    Draggable.create(droppables, {
      bounds: ".farms-page",
      onDrag: function (e) {
        if (this.hitTest(dropArea, overlapThreshold)) {
          $(this.target).addClass("highlight");
        } else {
          $(this.target).removeClass("highlight");
        }
      },
      onDragEnd: function (e) {
        if (!$(this.target).hasClass("highlight")) {
          let CSShapesDrag = gsap.timeline();
          CSShapesDrag.to(this.target, 0.2, {
            x: 0,
            y: 0,
          });
        }
      },
    });

    let CSShapesAnimBottomRight = gsap.timeline();
    CSShapesAnimBottomRight.fromTo(
      ".home-br1-img",
      {
        y: "-125vh",
      },
      {
        ease: Back.easeInOut,
        duration: 1.5,
        y: "0",
        delay: 0.5,
      }
    ).fromTo(
      ".home-br2-img",
      {
        y: "-125vh",
      },
      {
        ease: Back.easeInOut,
        duration: 1.5,
        y: "0",
      },
      "<0.15"
    );
    let CSShapesAnimRight = gsap.timeline();
    CSShapesAnimRight.fromTo(
      ".home-r1-img",
      {
        y: "125vh",
      },
      {
        ease: Back.easeInOut,
        duration: 1.5,
        y: "0",
        delay: 0.5,
      }
    )
      .fromTo(
        ".home-r2-img",
        {
          y: "125vh",
        },
        {
          ease: Back.easeInOut,
          duration: 1.5,
          y: "0",
        },
        "<0.15"
      )
      .fromTo(
        ".home-r3-img",
        {
          y: "125vh",
        },
        {
          ease: Back.easeInOut,
          duration: 1.5,
          y: "0",
        },
        "<0.15"
      );
    let CSShapesAnimLeft = gsap.timeline();
    CSShapesAnimLeft.fromTo(
      ".home-l1-img",
      {
        y: "-150vh",
      },
      {
        ease: Back.easeInOut,
        duration: 1.5,
        y: "0",
        delay: 0.5,
      }
    ).fromTo(
      ".home-l2-img",
      {
        y: "-150vh",
      },
      {
        ease: Back.easeInOut,
        duration: 1.5,
        y: "0",
      },
      "<0.15"
    );
  }, []);

  return (
    <main className="babybera-app">
      <div className="farms-page">
        <div data-speed={1.2} className="farms-left-shapes-box">
          <img
            className="farms-l1-img cs-rotatable-shapes cs-shapes"
            src={FarmsL1Img}
            alt="FarmsLImg"
          />
          <img
            className="farms-l2-img cs-rotatable-shapes cs-shapes"
            src={FarmsL2Img}
            alt="FarmsLImg"
          />
          <img
            className="farms-l3-img cs-draggable-shapes cs-shapes"
            src={FarmsL3Img}
            alt="FarmsLImg"
          />
          <img
            className="farms-l4-img cs-draggable-shapes cs-shapes"
            src={FarmsL4Img}
            alt="FarmsLImg"
          />
        </div>
        <div data-speed={1.3} className="farms-right-shapes-box">
          <img
            className="farms-r1-img cs-rotatable-shapes cs-shapes"
            src={FarmsR1Img}
            alt="FarmsLImg"
          />
          <img
            className="farms-r2-img cs-draggable-shapes cs-shapes"
            src={FarmsR2Img}
            alt="FarmsLImg"
          />
          <img
            className="farms-r3-img cs-draggable-shapes cs-shapes"
            src={FarmsR3Img}
            alt="FarmsLImg"
          />
        </div>
        <div className="berahouse-box">
          <div className="farms-top">
            <div className="farms-top-text">
              <h1>
                FARM
                <div>
                  {isDarkTheme ? (
                    <img src={FarmIconLight} alt="" className="farm-icon-img" />
                  ) : (
                    <img src={FarmIconDark} alt="" className="farm-icon-img" />
                  )}
                </div>
              </h1>
              <h2>deposit to earn bbbera</h2>
            </div>
            <div className="farms-head">
              <Nav variant="tabs" defaultActiveKey="a1">
                <Nav.Item>
                  <Nav.Link eventKey="a1">Active</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="a2">Inactive</Nav.Link>
                </Nav.Item>
              </Nav>
              <Form.Check
                type="switch"
                id="custom-switch"
                label="Staked only"
              />
            </div>
          </div>

          <div className="berahouse-grid">
            <div className="farms-head mob-farm-head">
              <Nav variant="tabs" defaultActiveKey="a1">
                <Nav.Item>
                  <Nav.Link eventKey="a1">Active</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="a2">Inactive</Nav.Link>
                </Nav.Item>
              </Nav>
              <Form.Check
                type="switch"
                id="custom-switch"
                label="Staked only"
              />
            </div>
            <FarmsCard
              showConnectWalletModal={showConnectWalletModalFun}
              showAPRModal={showAPRModalFun}
              iconImg={USDCIcon}
              exchangeCoinName={"USDC"}
              unlockwallet={"CONNECT WALLET"}
            />
            <FarmsCard
              showConnectWalletModal={showConnectWalletModalFun}
              showAPRModal={showAPRModalFun}
              iconImg={ETHIcon}
              exchangeCoinName={"ETH"}
              unlockwallet={"CONNECT WALLET"}
            />
            <FarmsCard
              showConnectWalletModal={showConnectWalletModalFun}
              showAPRModal={showAPRModalFun}
              iconImg={BTCIcon}
              exchangeCoinName={"BTC"}
              unlockwallet={"CONNECT WALLET"}
            />
          </div>
        </div>
      </div>
      <div id="dropArea"></div>
      <Modal
        centered
        show={showConnectWalletModal}
        onHide={hideConnectWalletModalFun}
      >
        <Modal.Body>
          <ConnectWalletModal
            hideConnectWalletModal={hideConnectWalletModalFun}
          />
        </Modal.Body>
      </Modal>

      <Modal centered show={showAPRModal} onHide={hideAPRModalFun}>
        <Modal.Body>
          <APRModal hideAPRModal={hideAPRModalFun} />
        </Modal.Body>
      </Modal>
    </main>
  );
};

export default ComingSoon;
