import React, { useEffect, useState, useRef } from "react";

// Styles
import "../styles/globals.css";
import "../styles/home.css";
import "../styles/comingsoon.css";
import "../styles/berahouses.css";
import "bootstrap/dist/css/bootstrap.min.css";
// Images
import Logo from "../assets/img/logo-1.png";
import BabyBeraImg from "../assets/img/desk.png";
import Logo2 from "../assets/img/logo-2.png";
import BeraEyesOpen from "../assets/img/aaa.png";
import BeraEyesClosed from "../assets/img/bbb.png";
import RightDoubleArrowIcon from "../assets/icons/right-double-arrow-icon.svg";
import HomeL1Img from "../assets/img/home-l1.png";
import HomeL2Img from "../assets/img/home-l2.png";
import HomeR1Img from "../assets/img/home-r1.png";
import HomeR2Img from "../assets/img/home-r2.png";
import HomeR3Img from "../assets/img/home-r3.png";
import HomeBR1Img from "../assets/img/home-br1.png";
import HomeBR2Img from "../assets/img/home-br2.png";
import HomeBL1Img from "../assets/img/home-bl1.png";
import HomeBL2Img from "../assets/img/home-bl2.png";
import HomeFloatingBubble1Img from "../assets/img/home-floating-bubble-1.png";
import HomeFloatingBubble2Img from "../assets/img/home-floating-bubble-2.png";
import HomeFloatingBubble3Img from "../assets/img/home-floating-bubble-3.png";
import HomeFloatingBubble4Img from "../assets/img/home-floating-bubble-4.png";
import BUSDImg from "../assets/img/BUSD.png";
import EGGImg from "../assets/img/EGG.png";
import BerahouseArrowImg from "../assets/img/berahouse-arrow.png";
import Modal from "react-bootstrap/Modal";
// Components
import gsap from "gsap";
import {
  Back,
  Power3,
  Power1,
  Power2,
  Power4,
  Linear,
  Expo,
  Circ,
} from "gsap/dist/gsap";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { Draggable } from "gsap/Draggable";
import { InertiaPlugin } from "gsap/InertiaPlugin";
import { SplitText } from "gsap/SplitText";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import $ from "jquery";
import { FaDiscord, FaMediumM, FaTwitter, FaPlay } from "react-icons/fa";
import { BsLockFill } from "react-icons/bs";

import {
  MdKeyboardArrowUp,
  MdKeyboardArrowDown,
  MdArrowDropDown,
  MdNorthEast,
  MdShare,
} from "react-icons/md";

import BeraHouseCard from "../components/BeraHouseCard";
import ConnectWalletModal from "../components/ConnectWalletModal";
import Header from "../components/Header";

const ComingSoon = () => {
  const [showConnectWalletModal, setShowConnectWalletModal] = useState(false);
  const hideConnectWalletModalFun = () => setShowConnectWalletModal(false);
  const showConnectWalletModalFun = () => setShowConnectWalletModal(true);

  gsap.registerPlugin(
    Draggable,
    SplitText,
    InertiaPlugin,
    ScrollSmoother,
    ScrollTrigger
  );

  useEffect(() => {
    ScrollSmoother.create({
      smooth: 1.15,
      effects: true,
      smoothTouch: 0,
    });

    gsap.fromTo(
      ".home-floating-bubble-1",
      {
        y: "-1rem",
      },
      {
        y: "1rem",
        ease: Linear.easeInOut,
        duration: 2,
        repeat: -1,
        yoyo: true,
      }
    );
    gsap.fromTo(
      ".home-floating-bubble-2",
      {
        y: "0",
      },
      {
        y: "1rem",
        ease: Linear.easeInOut,
        duration: 2,
        repeat: -1,
        yoyo: true,
      }
    );
    gsap.fromTo(
      ".home-floating-bubble-3",
      {
        y: "2rem",
      },
      {
        y: "0",
        ease: Linear.easeInOut,
        duration: 2,
        repeat: -1,
        yoyo: true,
      }
    );
    gsap.fromTo(
      ".home-floating-bubble-4",
      {
        y: "1rem",
      },
      {
        y: "-1rem",
        ease: Linear.easeInOut,
        duration: 2,
        repeat: -1,
        yoyo: true,
      }
    );
    Draggable.create(
      ".home-br2-img, .home-r2-img, .home-r3-img, .home-l2-img, .home-bl1-img",
      {
        type: "rotation",
        edgeResistance: 1,
        bounds: ".home-page",
        inertia: true,
        requestAnimationFrame,
      }
    );

    Draggable.create(
      ".home-l1-img, .home-bl2-img, .home-br1-img, .home-r1-img",
      {
        type: "x,y",
        edgeResistance: 1,
        bounds: ".home-page",
        inertia: true,
        requestAnimationFrame,
      }
    );

    var droppables = $(".cs-draggable-shapes");
    var dropArea = $("#dropArea");
    var overlapThreshold = "99%";
    Draggable.create(droppables, {
      bounds: ".home-page",
      onDrag: function (e) {
        if (this.hitTest(dropArea, overlapThreshold)) {
          $(this.target).addClass("highlight");
        } else {
          $(this.target).removeClass("highlight");
        }
      },
      onDragEnd: function (e) {
        if (!$(this.target).hasClass("highlight")) {
          let CSShapesDrag = gsap.timeline();
          CSShapesDrag.to(this.target, 0.2, {
            x: 0,
            y: 0,
          });
        }
      },
    });

    let CSShapesAnimBottomRight = gsap.timeline();
    CSShapesAnimBottomRight.fromTo(
      ".home-br1-img",
      {
        y: "-125vh",
      },
      {
        ease: Back.easeInOut,
        duration: 1.5,
        y: "0",
        delay: 0.5,
      }
    ).fromTo(
      ".home-br2-img",
      {
        y: "-125vh",
      },
      {
        ease: Back.easeInOut,
        duration: 1.5,
        y: "0",
      },
      "<0.15"
    );
    let CSShapesAnimRight = gsap.timeline();
    CSShapesAnimRight.fromTo(
      ".home-r1-img",
      {
        y: "125vh",
      },
      {
        ease: Back.easeInOut,
        duration: 1.5,
        y: "0",
        delay: 0.5,
      }
    )
      .fromTo(
        ".home-r2-img",
        {
          y: "125vh",
        },
        {
          ease: Back.easeInOut,
          duration: 1.5,
          y: "0",
        },
        "<0.15"
      )
      .fromTo(
        ".home-r3-img",
        {
          y: "125vh",
        },
        {
          ease: Back.easeInOut,
          duration: 1.5,
          y: "0",
        },
        "<0.15"
      );
    let CSShapesAnimLeft = gsap.timeline();
    CSShapesAnimLeft.fromTo(
      ".home-l1-img",
      {
        y: "-150vh",
      },
      {
        ease: Back.easeInOut,
        duration: 1.5,
        y: "0",
        delay: 0.5,
      }
    ).fromTo(
      ".home-l2-img",
      {
        y: "-150vh",
      },
      {
        ease: Back.easeInOut,
        duration: 1.5,
        y: "0",
      },
      "<0.15"
    );
  }, []);
  return (
    <div id="smooth-wrapper">
      <div id="smooth-content">
        <main className="babybera-app">
          <div className="home-page">
            <Header />
            <div className="berahouse-box">
              <h1>
                Bera Houses{" "}
                <svg
                  viewBox="0 0 56 56"
                  width="24px"
                  color="text"
                  xmlns="http://www.w3.org/2000/svg"
                  className="sc-bdfBwQ eNHRIG"
                >
                  <g>
                    <path
                      id="Shape"
                      d="m54.89 19.33c-1.65-3.16-4.39-5.33-7.89-5.33 1.9387753 4.8214002 2.9563558 9.9635739 3 15.16.1921013 6.0688357-2.3831761 11.8963206-7 15.84 5.82.28 10.9-2.58 12.85-8.55 1.83-5.66 1.45-12.53-.96-17.12z"
                    ></path>
                    <path
                      id="Shape"
                      d="m18.16 44.81c3.2315917 2.0831812 6.9951556 3.1907245 10.84 3.19 3.83925-.0374554 7.5924307-1.1419431 10.84-3.19 5.22-3.34 8.16-8.69 8.16-15.64 0-11.67-7.36-29.17-19-29.17-11.1 0-18.22 15.8-18.92 27-.48 7.47 1.67 13.7 8.08 17.81zm6.36-41.71c1.3871963-.71080538 2.9213462-1.08749398 4.48-1.1.5522847 0 1 .44771525 1 1s-.4477153 1-1 1c-1.2497589.01216582-2.4792834.31697831-3.59.89-.4916536.2469243-1.0904244.05031299-1.34-.44-.1210697-.23840257-.1416226-.51536113-.0570687-.76902291.0845539-.25366177.2671714-.46289687.5070687-.58097709zm4.36 25.9c-2.88-.37-6.88-.87-6.88-5.5 0-3.22 2.93-5.1 6-5.44v-2.06c0-.5522847.4477153-1 1-1s1 .4477153 1 1v2.05c4.27.37 5.81 3.07 5.88 3.19.1786328.3126074.1769222.696768-.0044873 1.0077722-.1814095.3110043-.5149577.5016032-.875.5-.3600423-.0016031-.6918799-.1951648-.8705127-.5077722-.06-.13-1.35-2.24-5.13-2.24-2.08 0-5 1.09-5 3.5 0 2.73 1.85 3.1 5.12 3.51 2.88.36 6.88.86 6.88 5.49 0 2.5-1.6 5.11-6 5.5v2c0 .5522847-.4477153 1-1 1s-1-.4477153-1-1v-2c-4-.2-5.54-1.59-5.71-1.76-.3921222-.3921222-.3921222-1.0278778 0-1.42s1.0278778-.3921222 1.42 0c1.54 1.33 10.29 2.6 10.29-2.32 0-2.73-1.85-3.1-5.12-3.5zm-12.28-17.48c1.0099054-1.85917744 2.2422964-3.58855444 3.67-5.15.3783151-.40316784 1.0118321-.42331501 1.415-.04499997.4031678.37831504.423315 1.0118321.045 1.41499997-1.3167051 1.43547667-2.4518907 3.0274234-3.38 4.74-.2761424.4832492-.8917508.6511424-1.375.375s-.6511424-.8917508-.375-1.375z"
                    ></path>
                    <path
                      id="Shape"
                      d="m15 45c-8.88-7.41-8.36-20.14-4-31-3.55 0-6.26 2.19-7.89 5.33-2.41 4.59-2.79 11.46-1 17.09 2.02 6.08 7.13 8.82 12.89 8.58z"
                    ></path>
                    <path
                      id="Shape"
                      d="m51.37 50.82 5.18-.49c-2.69-2-2.54-1.91-2.62-2-.3695814-.3660786-.5548165-.8790296-.5043991-1.3967767.0504173-.5177471.3311345-.9853244.7643991-1.2732233l.33-.18-2.79-.63c-3.3740505 1.9352367-7.3317613 2.5918972-11.15 1.85-7.0998733 4.3760158-16.0601267 4.3760158-23.16 0-3.823751.72837-7.78190651.0763551-11.17-1.84l-2.77.63.25.13c.459298.2749348.76648497.7459757.8328805 1.2771399.06639553.5311643-.11539102 1.0633268-.4928805 1.4428601-.08.08 0 0-2.62 2l5.15.49c.64038677.0713049 1.18894097.490663 1.42571345 1.0899279.23677249.5992649.12299447 1.2803137-.29571345 1.7700721l-.56.86 6.55-2.17c.9183874-.2960905 1.9231368-.1323536 2.7.44l2.51 1.88c.1137149.0896251.2677225.108876.4.05 6.09-2.89 5.67-2.75 6.15-2.75.4614024.000194.9038362.1836422 1.23.51.13.13 0 0 2.29 3.4 2.27-3.39 2.16-3.26 2.29-3.4.5872385-.5903148 1.5116873-.6785576 2.2-.21l5.18 2.44c.1322775.058876.2862851.0396251.4-.05l2.51-1.88c.7768632-.5723536 1.7816126-.7360905 2.7-.44l6.55 2.17-.56-.86c-.4172055-.4855792-.5340677-1.1610366-.3042517-1.7585583.2298161-.5975217.7691853-1.0205824 1.4042517-1.1014417z"
                    ></path>
                    <path
                      id="Shape"
                      d="m46.71 5.71 1-1c.2536586-.25365856.3527236-.62337399.2598781-.96987806-.0928455-.34650406-.363496-.61715454-.71-.71000002-.3465041-.09284548-.7162195.00621949-.9698781.25987808l-1 1c-.3921221.39212218-.3921221 1.02787781 0 1.41999996.3921222.39212216 1.0278778.39212218 1.42.00000004z"
                    ></path>
                    <path
                      id="Shape"
                      d="m48.32 9 3-1c.3394023-.11432499.5920313-.40103461.6627241-.75212813s-.0512905-.71323147-.32-.95-.6433218-.31219686-.9827241-.19787187l-3 1c-.3394023.11432498-.5920313.40103461-.6627241.75212813-.0706929.35109352.0512905.71323148.32.95000001s.6433218.31219685.9827241.19787186z"
                    ></path>
                    <path
                      id="Shape"
                      d="m12.71 4.29-1-1c-.2536586-.25365857-.623374-.35272352-.969878-.25987804-.3465041.09284548-.6171546.36349595-.71.71-.09284552.34650405.0062194.71621947.259878.96987804l1 1c.3921222.39212215 1.0278778.39212214 1.42-.00000002.3921221-.39212217.3921222-1.0278778 0-1.41999998z"
                    ></path>
                    <path
                      id="Shape"
                      d="m6.05 6.68c-.10156011.25804893-.09140308.54668422.02804477.7969559.11944785.25027169.33745932.43970373.60195523.5230441 3.14 1 3.04 1 3.32 1 .4927375.00386501.9148502-.35178371.9946449-.83803264.0797947-.48624892-.2065147-.95815151-.6746449-1.11196736l-3-1c-.25193645-.08555066-.52757913-.06717426-.76592958.05106258-.23835046.11823684-.41975528.32658479-.50407042.57893742z"
                    ></path>
                  </g>
                </svg>
              </h1>
              <h2>Stake EGG to earn BUSD and new tokens</h2>
              <div className="berahouse-grid">
                <BeraHouseCard
                  showConnectWalletModal={showConnectWalletModalFun}
                />
                <BeraHouseCard
                  showConnectWalletModal={showConnectWalletModalFun}
                />
                <BeraHouseCard
                  showConnectWalletModal={showConnectWalletModalFun}
                />
                <BeraHouseCard
                  showConnectWalletModal={showConnectWalletModalFun}
                />
                <BeraHouseCard
                  showConnectWalletModal={showConnectWalletModalFun}
                />
              </div>
            </div>
          </div>
          <div id="dropArea"></div>
          <Modal
            centered
            show={showConnectWalletModal}
            onHide={hideConnectWalletModalFun}
          >
            <Modal.Body>
              <ConnectWalletModal
                hideConnectWalletModal={hideConnectWalletModalFun}
              />
            </Modal.Body>
          </Modal>
        </main>
      </div>
    </div>
  );
};

export default ComingSoon;
