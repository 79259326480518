import React, { useEffect, useState, useRef } from "react";

// Styles
import "../styles/globals.css";
import "../styles/home.css";
import "../styles/comingsoon.css";
import "../styles/berahouses.css";

import BUSDImg from "../assets/img/BUSD.png";
import EGGImg from "../assets/img/EGG.png";
import USDCIcon from "../assets/img/usdc.png";
import ETHIcon from "../assets/img/eth.png";
import BeraIcon from "../assets/img/aaa.png";
import BTCIcon from "../assets/img/btc.png";
import BerahouseArrowImg from "../assets/img/berahouse-arrow.png";
import { BsLockFill, BsPatchCheckFill } from "react-icons/bs";
import {
  MdKeyboardArrowUp,
  MdKeyboardArrowDown,
  MdNorthEast,
  MdShare,
  MdOutlineCalculate,
} from "react-icons/md";
import gsap from "gsap";
import {
  Back,
  Power3,
  Power1,
  Power2,
  Power4,
  Linear,
  Expo,
  Circ,
} from "gsap/dist/gsap";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { Draggable } from "gsap/Draggable";
import { InertiaPlugin } from "gsap/InertiaPlugin";
import { SplitText } from "gsap/SplitText";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import $ from "jquery";

const BeraHouseCard = (props) => {
  const [isDetailsBoxShowing, setIsDetailsBoxShowing] = useState(false);

  return (
    <div className="berahouse-grid-card">
      <div className="farms-card-top">
        <div className="fct-left">
          <div>
            <img src={BeraIcon} alt="" />
            <img src={props.iconImg} alt="" />
          </div>
          <h6>{props.exchangeCoinName}</h6>
        </div>
        <div className="fct-right">
          <span>3.2 X</span>
          <p>Fee: 4.20%</p>
        </div>
      </div>
      <div className="bgc-data-box">
        <p>APR:</p>
        <h6>
          {" "}
          <span onClick={props.showAPRModal}>
            <MdOutlineCalculate />
          </span>{" "}
          43.73%
        </h6>
      </div>
      <div className="bgc-data-box">
        <p>Stake:</p>
        <h6>{props.exchangeCoinName}</h6>
      </div>
      <div className="bgc-data-box">
        <p>Earn:</p>
        <h6>BBBERA</h6>
      </div>
      <div className="bgc-data-box bgc-data-box-special">
        <p>Deposit Fee:</p>
        <h6>0</h6>
      </div>
      <div className="bgc-btn-box">
        <span>BBBera Earned</span>
        <div className="bgc-data-box">
          <h6>0 </h6>
          <button className="bb-btn-grd-fill">
            <span>Harvest</span>
          </button>
        </div>
      </div>
      <div className="bgc-btn-box">
        <span>EGG Staked</span>
        <button onClick={props.showConnectWalletModal} className="bb-btn-grd">
          <span>
            <i>{props.unlockwallet}</i>
            <BsLockFill />
          </span>
        </button>
      </div>
      <div className="bgc-divider"></div>
      <h5
        className="show-details"
        onClick={() => {
          setIsDetailsBoxShowing(true);
        }}
      >
        Show Details <MdKeyboardArrowUp />
      </h5>
      {isDetailsBoxShowing ? (
        <div className="bgc-details-box">
          <h5
            className="hide-details"
            onClick={() => {
              setIsDetailsBoxShowing(false);
            }}
          >
            Hide Details <MdKeyboardArrowDown />
          </h5>
          <div className="bgc-data-box">
            <p>Deposit: :</p>
            <h6>
              <a href="#">
                {props.exchangeCoinName} <MdShare />
              </a>
            </h6>
          </div>
          <div className="bgc-data-box">
            <p>Emission Rate:</p>
            <h6>0 / BLOCK</h6>
          </div>
          <div className="bgc-data-box">
            <p>Total Value:</p>
            <h6>$745</h6>
          </div>
          {/* <a href="#">
            View Project Site <MdNorthEast />
          </a> */}
        </div>
      ) : null}
    </div>
  );
};

export default BeraHouseCard;
