import React, { useState, useRef } from "react";

// Styles
import "../styles/globals.css";
import "../styles/home.css";
import "../styles/comingsoon.css";
import "../styles/berahouses.css";
import "../styles/farms.css";
import "bootstrap/dist/css/bootstrap.min.css";

import StakeVid from "../assets/videos/stake-vid.mp4";

import {
  BsLockFill,
  BsDash,
  BsPlay,
  BsPlayBtnFill,
  BsPause,
} from "react-icons/bs";

const StakeCard = (props) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const stakeVideo = useRef();
  return (
    <div className="stake-card">
      <input type="radio" name="stakecard" id={props.stakeCardIndex} />
      <label htmlFor={props.stakeCardIndex}>
        <div className="stake-card-content">
          <div className="video-box">
            {isVideoPlaying ? (
              <div
                className="pause-btn"
                onClick={() => {
                  stakeVideo.current.pause();
                  setIsVideoPlaying(false);
                }}
              >
                <BsPause />
              </div>
            ) : (
              <div
                className="play-btn"
                onClick={() => {
                  stakeVideo.current.play();
                  setIsVideoPlaying(true);
                }}
              >
                <BsPlay />
              </div>
            )}

            <video src={StakeVid} ref={stakeVideo} />
          </div>
          <button className="bb-btn-grd-fill bb-btn-grd-fill-lg">
            <span>Stake</span>
          </button>
        </div>
      </label>
    </div>
  );
};

export default StakeCard;
