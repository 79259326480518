import React, { useEffect, useState, useRef } from "react";

// Styles
import "../styles/globals.css";
import "../styles/home.css";
import "../styles/comingsoon.css";
import "../styles/berahouses.css";
import MetamaskImg from "../assets/img/metamask-icon.png";
import { MdClose } from "react-icons/md";

const ConnectWalletModal = (props) => {
  return (
    <div className="connect-wallet-modal">
      <div className="connect-wallet-modal-box">
        <div className="cwm-header">
          <h3>ROI</h3>
          <div onClick={props.hideAPRModal}>
            <MdClose />
          </div>
        </div>
        <div className="cwm-divider"></div>
        <div className="apr-modal-content">
          <table id="customers">
            <tr>
              <th>TIMEFRAME</th>
              <th>ROI</th>
              <th>BBBERA PER $1000</th>
            </tr>
            <tr>
              <td>1d</td>
              <td>0.14%</td>
              <td>59.29</td>
            </tr>
            <tr>
              <td>7d</td>
              <td>0.97%</td>
              <td>416.76</td>
            </tr>
            <tr>
              <td>30d</td>
              <td>4.24%</td>
              <td>1814.9</td>
            </tr>
            <tr>
              <td>365d(APY)</td>
              <td>65.71%</td>
              <td>28135.97</td>
            </tr>
          </table>
          <p>
            Calculated based on current rates. Compounding once daily. Rates are
            estimates provided for your convenience only, and by no means
            represent guaranteed returns.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ConnectWalletModal;
