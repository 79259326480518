import React, { useEffect, useState, useRef, useContext } from "react";

// Styles
import "../styles/globals.css";
import "../styles/home.css";
import "../styles/comingsoon.css";
import "../styles/berahouses.css";
import "../styles/farms.css";
import "bootstrap/dist/css/bootstrap.min.css";
// Images
import Logo from "../assets/img/logo-1.png";
import BabyBeraImg from "../assets/img/desk.png";
import Logo2 from "../assets/img/logo-2.png";
import BTCIcon from "../assets/img/btc.png";
import USDCIcon from "../assets/img/usdc.png";
import ETHIcon from "../assets/img/eth.png";
import BeraEyesOpen from "../assets/img/aaa.png";
import BeraEyesClosed from "../assets/img/bbb.png";
import RightDoubleArrowIcon from "../assets/icons/right-double-arrow-icon.svg";

import StakeL1Img from "../assets/img/s-l1-img.png";
import StakeL2Img from "../assets/img/s-l2-img.png";
import StakeL3Img from "../assets/img/s-l3-img.png";
import StakeL4Img from "../assets/img/s-l4-img.png";
import StakeR1Img from "../assets/img/s-r1-img.png";
import StakeR2Img from "../assets/img/s-r2-img.png";
import StakeR3Img from "../assets/img/s-r3-img.png";
import HomeFloatingBubble1Img from "../assets/img/home-floating-bubble-1.png";
import HomeFloatingBubble2Img from "../assets/img/home-floating-bubble-2.png";
import HomeFloatingBubble3Img from "../assets/img/home-floating-bubble-3.png";
import HomeFloatingBubble4Img from "../assets/img/home-floating-bubble-4.png";
import BUSDImg from "../assets/img/BUSD.png";
import EGGImg from "../assets/img/EGG.png";
import StakeIconDark from "../assets/icons/stake-dark.png";
import StakeIconLight from "../assets/icons/stake-light.png";
import BerahouseArrowImg from "../assets/img/berahouse-arrow.png";
import StakeVid from "../assets/videos/stake-vid.mp4";
import Modal from "react-bootstrap/Modal";
import { ThemeContext } from "../ThemeContext";

// Components
import gsap from "gsap";
import {
  Back,
  Power3,
  Power1,
  Power2,
  Power4,
  Linear,
  Expo,
  Circ,
} from "gsap/dist/gsap";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { Draggable } from "gsap/Draggable";
import { InertiaPlugin } from "gsap/InertiaPlugin";
import { SplitText } from "gsap/SplitText";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import $ from "jquery";
import {
  FaDiscord,
  FaMediumM,
  FaTwitter,
  FaPlay,
  FaMinus,
} from "react-icons/fa";
import {
  BsLockFill,
  BsDash,
  BsPlay,
  BsPlayBtnFill,
  BsPause,
} from "react-icons/bs";

import {
  MdKeyboardArrowUp,
  MdKeyboardArrowDown,
  MdArrowDropDown,
  MdNorthEast,
  MdShare,
  MdAdd,
} from "react-icons/md";

import BeraHouseCard from "../components/BeraHouseCard";
import FarmsCard from "../components/FarmsCard";
import ConnectWalletModal from "../components/ConnectWalletModal";
import APRModal from "../components/APRModal";
import { Form, Nav } from "react-bootstrap";
import Header from "../components/Header";
import StakeCard from "../components/StakeCard";

const StakeNew = () => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const stakeVideo = useRef();
  const [showConnectWalletModal, setShowConnectWalletModal] = useState(false);
  const hideConnectWalletModalFun = () => setShowConnectWalletModal(false);
  const showConnectWalletModalFun = () => setShowConnectWalletModal(true);
  const { isDarkTheme, toggleTheme } = useContext(ThemeContext);

  const [showAPRModal, setShowAPRModal] = useState(false);
  const hideAPRModalFun = () => setShowAPRModal(false);
  const showAPRModalFun = () => setShowAPRModal(true);

  gsap.registerPlugin(
    Draggable,
    SplitText,
    InertiaPlugin,
    ScrollSmoother,
    ScrollTrigger
  );

  useEffect(() => {
    ScrollSmoother.create({
      smooth: 1.15,
      effects: true,
      smoothTouch: 0,
    });

    gsap.fromTo(
      ".home-floating-bubble-1",
      {
        y: "-1rem",
      },
      {
        y: "1rem",
        ease: Linear.easeInOut,
        duration: 2,
        repeat: -1,
        yoyo: true,
      }
    );
    gsap.fromTo(
      ".home-floating-bubble-2",
      {
        y: "0",
      },
      {
        y: "1rem",
        ease: Linear.easeInOut,
        duration: 2,
        repeat: -1,
        yoyo: true,
      }
    );
    gsap.fromTo(
      ".home-floating-bubble-3",
      {
        y: "2rem",
      },
      {
        y: "0",
        ease: Linear.easeInOut,
        duration: 2,
        repeat: -1,
        yoyo: true,
      }
    );
    gsap.fromTo(
      ".home-floating-bubble-4",
      {
        y: "1rem",
      },
      {
        y: "-1rem",
        ease: Linear.easeInOut,
        duration: 2,
        repeat: -1,
        yoyo: true,
      }
    );
    Draggable.create(".stake-l1-img, .stake-l2-img,.stake-r1-img", {
      type: "rotation",
      edgeResistance: 1,
      bounds: ".farms-page",
      inertia: true,
      requestAnimationFrame,
    });

    Draggable.create(
      ".stake-l3-img, .stake-l4-img, .stake-r2-img, .stake-r3-img",
      {
        type: "x,y",
        edgeResistance: 1,
        bounds: ".farms-page",
        inertia: true,
        requestAnimationFrame,
      }
    );

    var droppables = $(".cs-draggable-shapes");
    var dropArea = $("#dropArea");
    var overlapThreshold = "99%";
    Draggable.create(droppables, {
      bounds: ".farms-page",
      onDrag: function (e) {
        if (this.hitTest(dropArea, overlapThreshold)) {
          $(this.target).addClass("highlight");
        } else {
          $(this.target).removeClass("highlight");
        }
      },
      onDragEnd: function (e) {
        if (!$(this.target).hasClass("highlight")) {
          let CSShapesDrag = gsap.timeline();
          CSShapesDrag.to(this.target, 0.2, {
            x: 0,
            y: 0,
          });
        }
      },
    });

    let CSShapesAnimBottomRight = gsap.timeline();
    CSShapesAnimBottomRight.fromTo(
      ".home-br1-img",
      {
        y: "-125vh",
      },
      {
        ease: Back.easeInOut,
        duration: 1.5,
        y: "0",
        delay: 0.5,
      }
    ).fromTo(
      ".home-br2-img",
      {
        y: "-125vh",
      },
      {
        ease: Back.easeInOut,
        duration: 1.5,
        y: "0",
      },
      "<0.15"
    );
    let CSShapesAnimRight = gsap.timeline();
    CSShapesAnimRight.fromTo(
      ".home-r1-img",
      {
        y: "125vh",
      },
      {
        ease: Back.easeInOut,
        duration: 1.5,
        y: "0",
        delay: 0.5,
      }
    )
      .fromTo(
        ".home-r2-img",
        {
          y: "125vh",
        },
        {
          ease: Back.easeInOut,
          duration: 1.5,
          y: "0",
        },
        "<0.15"
      )
      .fromTo(
        ".home-r3-img",
        {
          y: "125vh",
        },
        {
          ease: Back.easeInOut,
          duration: 1.5,
          y: "0",
        },
        "<0.15"
      );
    let CSShapesAnimLeft = gsap.timeline();
    CSShapesAnimLeft.fromTo(
      ".home-l1-img",
      {
        y: "-150vh",
      },
      {
        ease: Back.easeInOut,
        duration: 1.5,
        y: "0",
        delay: 0.5,
      }
    ).fromTo(
      ".home-l2-img",
      {
        y: "-150vh",
      },
      {
        ease: Back.easeInOut,
        duration: 1.5,
        y: "0",
      },
      "<0.15"
    );
  }, []);

  return (
    <main className="babybera-app">
      <div className="farms-page">
        <div data-speed={1.2} className="stake-left-shapes-box">
          <img
            className="stake-l1-img cs-rotatable-shapes cs-shapes"
            src={StakeL1Img}
            alt="StakeLImg"
          />
          <img
            className="stake-l2-img cs-rotatable-shapes cs-shapes"
            src={StakeL2Img}
            alt="StakeLImg"
          />
          <img
            className="stake-l3-img cs-draggable-shapes cs-shapes"
            src={StakeL3Img}
            alt="StakeLImg"
          />
          <img
            className="stake-l4-img cs-draggable-shapes cs-shapes"
            src={StakeL4Img}
            alt="StakeLImg"
          />
        </div>
        <div data-speed={1.3} className="stake-right-shapes-box">
          <img
            className="stake-r1-img cs-rotatable-shapes cs-shapes"
            src={StakeR1Img}
            alt="StakeLImg"
          />
          <img
            className="stake-r2-img cs-draggable-shapes cs-shapes"
            src={StakeR2Img}
            alt="StakeLImg"
          />
          <img
            className="stake-r3-img cs-draggable-shapes cs-shapes"
            src={StakeR3Img}
            alt="StakeLImg"
          />
        </div>
        <div className="berahouse-box">
          <div className="farms-top">
            <div className="farms-top-text">
              <h1>
                STAKE{" "}
                <div>
                  {isDarkTheme ? (
                    <img src={StakeIconLight} alt="StakeIcon" />
                  ) : (
                    <img src={StakeIconDark} alt="StakeIcon" />
                  )}
                </div>
              </h1>
              <h2>stake vibe pass for yield boost</h2>
            </div>
          </div>
          <div className="stake-main-grid">
            <StakeCard stakeCardIndex={1} />
            <StakeCard stakeCardIndex={2} />
            <StakeCard stakeCardIndex={3} />
            <StakeCard stakeCardIndex={4} />
            <StakeCard stakeCardIndex={5} />
          </div>
        </div>
      </div>
      <div id="dropArea"></div>
      <Modal
        centered
        show={showConnectWalletModal}
        onHide={hideConnectWalletModalFun}
      >
        <Modal.Body>
          <ConnectWalletModal
            hideConnectWalletModal={hideConnectWalletModalFun}
          />
        </Modal.Body>
      </Modal>

      <Modal centered show={showAPRModal} onHide={hideAPRModalFun}>
        <Modal.Body>
          <APRModal hideAPRModal={hideAPRModalFun} />
        </Modal.Body>
      </Modal>
    </main>
  );
};

export default StakeNew;
