import React, { useEffect, useState, useRef } from "react";

// Styles
import "../styles/globals.css";
import "../styles/home.css";
import "../styles/comingsoon.css";

// Images
import Logo from "../assets/img/logo-1.png";
import BabyBeraImg from "../assets/img/desk.png";
import Logo2 from "../assets/img/logo-2.png";
import BeraEyesOpen from "../assets/img/aaa.png";
import BeraEyesClosed from "../assets/img/bbb.png";
import RightDoubleArrowIcon from "../assets/icons/right-double-arrow-icon.svg";
import YieldFarmImg from "../assets/img/6.png";
import HomeL1Img from "../assets/img/home-l1.png";
import HomeL2Img from "../assets/img/home-l2.png";
import HomeR1Img from "../assets/img/home-r1.png";
import HomeR2Img from "../assets/img/home-r2.png";
import HomeR3Img from "../assets/img/home-r3.png";
import HomeBR1Img from "../assets/img/home-br1.png";
import HomeBR2Img from "../assets/img/home-br2.png";
import HomeBL1Img from "../assets/img/home-bl1.png";
import HomeBL2Img from "../assets/img/home-bl2.png";
import L1Img from "../assets/img/l1.png";
import L2Img from "../assets/img/l2.png";
import L3Img from "../assets/img/l3.png";
import L4Img from "../assets/img/l4.png";
import L5Img from "../assets/img/l5.png";
import R1Img from "../assets/img/r1.png";
import R2Img from "../assets/img/r2.png";
import R3Img from "../assets/img/r3.png";
import R4Img from "../assets/img/r4.png";
import R5Img from "../assets/img/r5.png";
import T1Img from "../assets/img/t1.png";
import T2Img from "../assets/img/t2.png";
import T3Img from "../assets/img/t3.png";
import T4Img from "../assets/img/t4.png";
import HomeFloatingBubble1Img from "../assets/img/home-floating-bubble-1.png";
import HomeFloatingBubble2Img from "../assets/img/home-floating-bubble-2.png";
import HomeFloatingBubble3Img from "../assets/img/home-floating-bubble-3.png";
import HomeFloatingBubble4Img from "../assets/img/home-floating-bubble-4.png";

// Components
import gsap from "gsap";
import {
  Back,
  Power3,
  Power1,
  Power2,
  Power4,
  Linear,
  Expo,
  Circ,
} from "gsap/dist/gsap";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { Draggable } from "gsap/Draggable";
import { InertiaPlugin } from "gsap/InertiaPlugin";
import { SplitText } from "gsap/SplitText";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import $ from "jquery";
import { FaDiscord, FaMediumM, FaTwitter, FaPlay } from "react-icons/fa";
import { MdClose, MdKeyboardArrowRight, MdMenu } from "react-icons/md";
import Header from "../components/Header";

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Autoplay } from "swiper";

const ComingSoon = () => {
  gsap.registerPlugin(
    Draggable,
    SplitText,
    InertiaPlugin,
    ScrollSmoother,
    ScrollTrigger
  );

  useEffect(() => {
    ScrollSmoother.create({
      smooth: 1.15,
      effects: true,
      smoothTouch: 0,
    });

    gsap.fromTo(
      ".cs-dot",
      {
        opacity: 0,
      },
      {
        opacity: 1,
        repeat: -1,
        duration: 1,
        stagger: {
          each: 0.5,
        },
      }
    );
    /* const TotalValueBox = new SplitText(".total-value-box h4", {
      type: "chars",
    });
    const TotalValueBoxChars = TotalValueBox.chars;
    let TotalValueBoxAnim = gsap.timeline({
      scrollTrigger: {
        trigger: ".hero-section",
        start: "bottom 0%",
      },
    });
    TotalValueBoxAnim.fromTo(
      TotalValueBoxChars,
      {
        opacity: 0,
      },
      {
        opacity: 1,
      }
    ); */
    gsap.fromTo(
      ".home-floating-bubble-1",
      {
        y: "-1rem",
      },
      {
        y: "1rem",
        ease: Linear.easeInOut,
        duration: 2,
        repeat: -1,
        yoyo: true,
      }
    );
    gsap.fromTo(
      ".home-floating-bubble-2",
      {
        y: "0",
      },
      {
        y: "1rem",
        ease: Linear.easeInOut,
        duration: 2,
        repeat: -1,
        yoyo: true,
      }
    );
    gsap.fromTo(
      ".home-floating-bubble-3",
      {
        y: "2rem",
      },
      {
        y: "0",
        ease: Linear.easeInOut,
        duration: 2,
        repeat: -1,
        yoyo: true,
      }
    );
    gsap.fromTo(
      ".home-floating-bubble-4",
      {
        y: "1rem",
      },
      {
        y: "-1rem",
        ease: Linear.easeInOut,
        duration: 2,
        repeat: -1,
        yoyo: true,
      }
    );
    Draggable.create(
      ".cs-l2-img, .cs-l1-img, .cs-t2-img, .cs-t1-img, .cs-r2-img, .cs-r1-img",
      {
        type: "rotation",
        edgeResistance: 1,
        bounds: ".home-page",
        inertia: true,
        requestAnimationFrame,
      }
    );

    Draggable.create(
      ".home-l1-img, .home-bl2-img, .home-br1-img, .home-r1-img",
      {
        type: "x,y",
        edgeResistance: 1,
        bounds: ".home-page",
        inertia: true,
        requestAnimationFrame,
      }
    );

    var droppables = $(".cs-draggable-shapes");
    var dropArea = $("#dropArea");
    var overlapThreshold = "99%";
    Draggable.create(droppables, {
      bounds: ".home-page",
      onDrag: function (e) {
        if (this.hitTest(dropArea, overlapThreshold)) {
          $(this.target).addClass("highlight");
        } else {
          $(this.target).removeClass("highlight");
        }
      },
      onDragEnd: function (e) {
        if (!$(this.target).hasClass("highlight")) {
          let CSShapesDrag = gsap.timeline();
          CSShapesDrag.to(this.target, 0.2, {
            x: 0,
            y: 0,
          });
        }
      },
    });

    let CSShapesAnimBottomRight = gsap.timeline();
    CSShapesAnimBottomRight.fromTo(
      ".home-br1-img",
      {
        y: "-125vh",
      },
      {
        ease: Back.easeInOut,
        duration: 1.5,
        y: "0",
        delay: 0.5,
      }
    ).fromTo(
      ".home-br2-img",
      {
        y: "-125vh",
      },
      {
        ease: Back.easeInOut,
        duration: 1.5,
        y: "0",
      },
      "<0.15"
    );

    let CSShapesAnimLeftMob = gsap.timeline();
    CSShapesAnimLeftMob.fromTo(
      ".cs-left-shapes-box-mob .cs-l1-img",
      {
        y: "100vh",
      },
      {
        y: "0",
        duration: 1.5,
        ease: Back.easeInOut,
        delay: 0.5,
      }
    )
      .fromTo(
        ".cs-left-shapes-box-mob .cs-l2-img",
        {
          y: "100vh",
        },
        {
          y: "0",
          duration: 1.5,
          ease: Back.easeInOut,
        },
        "<0.15"
      )
      .fromTo(
        ".cs-left-shapes-box-mob .cs-l3-img",
        {
          y: "100vh",
        },
        {
          y: "0",
          duration: 1.5,
          ease: Back.easeInOut,
        },
        "<0.15"
      )
      .fromTo(
        ".cs-left-shapes-box-mob .cs-l4-img",
        {
          y: "100vh",
        },
        {
          y: "0",
          duration: 1.5,
          ease: Back.easeInOut,
        },
        "<0.15"
      )
      .fromTo(
        ".cs-left-shapes-box-mob .cs-l5-img",
        {
          y: "100vh",
        },
        {
          y: "0",
          duration: 1.5,
          ease: Back.easeInOut,
        },
        "<0.15"
      );

    let CSShapesAnimLeftPC = gsap.timeline();
    CSShapesAnimLeftPC.fromTo(
      ".cs-left-shapes-box-pc .cs-l1-img",
      {
        y: "-100vh",
      },
      {
        y: "0",
        duration: 1.5,
        ease: Back.easeInOut,
      }
    )
      .fromTo(
        ".cs-left-shapes-box-pc .cs-l2-img",
        {
          y: "-100vh",
        },
        {
          y: "0",
          duration: 1.5,
          ease: Back.easeInOut,
        },
        "<0.15"
      )
      .fromTo(
        ".cs-left-shapes-box-pc .cs-l3-img",
        {
          y: "-100vh",
        },
        {
          y: "0",
          duration: 1.5,
          ease: Back.easeInOut,
        },
        "<0.15"
      )
      .fromTo(
        ".cs-left-shapes-box-pc .cs-l4-img",
        {
          y: "-100vh",
        },
        {
          y: "0",
          duration: 1.5,
          ease: Back.easeInOut,
        },
        "<0.15"
      )
      .fromTo(
        ".cs-left-shapes-box-pc .cs-l5-img",
        {
          y: "-100vh",
        },
        {
          y: "0",
          duration: 1.5,
          ease: Back.easeInOut,
        },
        "<0.15"
      );
    let CSShapesAnimRight = gsap.timeline();
    CSShapesAnimRight.fromTo(
      ".cs-r1-img",
      {
        y: "100vh",
      },
      {
        ease: Back.easeInOut,
        duration: 1.5,
        y: "0",
        delay: 0.5,
      }
    )
      .fromTo(
        ".cs-r2-img",
        {
          y: "100vh",
        },
        {
          ease: Back.easeInOut,
          duration: 1.5,
          y: "0",
        },
        "<0.15"
      )
      .fromTo(
        ".cs-r3-img",
        {
          y: "100vh",
        },
        {
          ease: Back.easeInOut,
          duration: 1.5,
          y: "0",
        },
        "<0.15"
      )
      .fromTo(
        ".cs-r4-img",
        {
          y: "100vh",
        },
        {
          ease: Back.easeInOut,
          duration: 1.5,
          y: "0",
        },
        "<0.15"
      )
      .fromTo(
        ".cs-r5-img",
        {
          y: "100vh",
        },
        {
          ease: Back.easeInOut,
          duration: 1.5,
          y: "0",
        },
        "<0.15"
      )
      .fromTo(
        ".bera-eyes-open",
        {
          opacity: 1,
        },
        {
          duration: 0.25,
          opacity: 0,
        }
      );

    let CSShapesAnimTop = gsap.timeline();
    CSShapesAnimTop.fromTo(
      ".cs-t1-img",
      {
        y: "100vh",
      },
      {
        ease: Back.easeInOut,
        duration: 1.5,
        y: "0",
        delay: 0.5,
      }
    )
      .fromTo(
        ".cs-t2-img",
        {
          y: "100vh",
        },
        {
          ease: Back.easeInOut,
          duration: 1.5,
          y: "0",
        },
        "<0.15"
      )
      .fromTo(
        ".cs-t3-img",
        {
          y: "100vh",
        },
        {
          ease: Back.easeInOut,
          duration: 1.5,
          y: "0",
        },
        "<0.15"
      )
      .fromTo(
        ".cs-t4-img",
        {
          y: "100vh",
        },
        {
          ease: Back.easeInOut,
          duration: 1.5,
          y: "0",
        },
        "<0.15"
      );
  }, []);
  return (
    <div id="smooth-wrapper">
      <div id="smooth-content">
        <main className="babybera-app">
          <div className="home-page">
            <Header />
            <div className="home-hero-cs-box">
              <div className="cs-content cs-content-home">
                <div className="cs-babybera-box">
                  <img
                    className="bera-desk"
                    src={BabyBeraImg}
                    alt="BabyBeraImg"
                  />
                  <img
                    className="bera-eyes-open"
                    src={BeraEyesOpen}
                    alt="BabyBeraImg"
                  />
                  <img
                    className="bera-eyes-closed"
                    src={BeraEyesClosed}
                    alt="BabyBeraImg"
                  />
                </div>
                <div className="cs-heading-box">
                  <h1>Henlo</h1>
                  <div className="cs-dot-box">
                    <div className="cs-dot"></div>
                    <div className="cs-dot"></div>
                    <div className="cs-dot"></div>
                  </div>
                </div>
                <div className="cs-berachain-box">
                  <div className="cs-next-text-box">
                    <Swiper
                      slidesPerView={"1"}
                      freeMode={false}
                      modules={[Autoplay, FreeMode]}
                      className="mySwiper"
                      loop={true}
                      speed={2000}
                      autoplay={{
                        delay: 10,
                        disableOnInteraction: true,
                        reverseDirection: true,
                      }}
                      direction={"vertical"}
                    >
                      <SwiperSlide>
                        <span>nft</span>
                      </SwiperSlide>
                      <SwiperSlide>
                        <span>memecoin</span>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                  <span>on</span>
                  <a target={"_blank"} href="https://berachain.com/">
                    <img src={YieldFarmImg} alt="BabyBera Shape" />
                  </a>
                </div>
                <div className="cs-left-shapes-box cs-left-shapes-box-pc">
                  <img
                    className="cs-l1-img  cs-rotatable-shapes cs-shapes"
                    src={L1Img}
                    alt="BabyBera Shape"
                  />
                  <img
                    className="cs-l2-img  cs-rotatable-shapes cs-shapes"
                    src={L2Img}
                    alt="BabyBera Shape"
                  />
                  <img
                    className="cs-l3-img cs-draggable-shapes cs-shapes"
                    src={L3Img}
                    alt="BabyBera Shape"
                  />
                  <img
                    className="cs-l4-img cs-draggable-shapes cs-shapes"
                    src={L4Img}
                    alt="BabyBera Shape"
                  />
                  <img
                    className="cs-l5-img cs-draggable-shapes cs-shapes"
                    src={L5Img}
                    alt="BabyBera Shape"
                  />
                </div>
                <div className="cs-left-shapes-box cs-left-shapes-box-mob">
                  <img
                    className="cs-l1-img  cs-rotatable-shapes cs-shapes"
                    src={L1Img}
                    alt="BabyBera Shape"
                  />
                  <img
                    className="cs-l2-img  cs-rotatable-shapes cs-shapes"
                    src={L2Img}
                    alt="BabyBera Shape"
                  />
                  <img
                    className="cs-l3-img cs-draggable-shapes cs-shapes"
                    src={L3Img}
                    alt="BabyBera Shape"
                  />
                  <img
                    className="cs-l4-img cs-draggable-shapes cs-shapes"
                    src={L4Img}
                    alt="BabyBera Shape"
                  />
                  <img
                    className="cs-l5-img cs-draggable-shapes cs-shapes"
                    src={L5Img}
                    alt="BabyBera Shape"
                  />
                </div>
                <div className="cs-right-shapes-box">
                  <img
                    className="cs-r1-img cs-rotatable-shapes cs-shapes"
                    src={R1Img}
                    alt="BabyBera Shape"
                  />
                  <img
                    className="cs-r2-img cs-rotatable-shapes cs-shapes"
                    src={R2Img}
                    alt="BabyBera Shape"
                  />
                  <img
                    className="cs-r3-img cs-draggable-shapes cs-shapes"
                    src={R3Img}
                    alt="BabyBera Shape"
                  />
                  <img
                    className="cs-r4-img cs-draggable-shapes cs-shapes"
                    src={R4Img}
                    alt="BabyBera Shape"
                  />
                  <img
                    className="cs-r5-img cs-draggable-shapes cs-shapes"
                    src={R5Img}
                    alt="BabyBera Shape"
                  />
                </div>
                <div className="cs-top-shapes-box">
                  <img
                    className="cs-t1-img cs-rotatable-shapes cs-shapes"
                    src={T1Img}
                    alt="BabyBera Shape"
                  />
                  <img
                    className="cs-t2-img cs-rotatable-shapes cs-shapes"
                    src={T2Img}
                    alt="BabyBera Shape"
                  />
                  <img
                    className="cs-t3-img cs-draggable-shapes cs-shapes"
                    src={T3Img}
                    alt="BabyBera Shape"
                  />
                  <img
                    className="cs-t4-img cs-draggable-shapes cs-shapes"
                    src={T4Img}
                    alt="BabyBera Shape"
                  />
                </div>
              </div>
            </div>
            {/* <section className="hero-section">
              <div className="box">
                <div className="hero-section-content">
                  <h1>Deploy Your Crypto Into DeFi</h1>
                  <p>
                    Earn a yield, Multiply your exposure or Borrow against your
                    crypto. ETH, BTC and 30 more cryptos available to put to
                    work.
                  </p>
                  <button className="bb-btn-grd">
                    <span>
                      <i>Connect Wallet</i>
                      <img
                        src={RightDoubleArrowIcon}
                        alt="RightDoubleArrowIcon"
                      />
                    </span>
                  </button>
                  <div className="hero-tags-box">
                    <span>ETH</span>
                    <span>BTC</span>
                    <span>DAI</span>
                    <span>UNI</span>
                    <span>LINK</span>
                    <span>BERA</span>
                  </div>
                </div>
                <div className="home-br-shapes-box" data-speed={1.5}>
                  <img
                    className="home-br1-img cs-draggable-shapes"
                    src={HomeBR1Img}
                    alt="BabyBera Shape"
                  />
                  <img
                    className="home-br2-img"
                    src={HomeBR2Img}
                    alt="BabyBera Shape"
                  />
                </div>
                <div className="home-right-shapes-box" data-speed={1.35}>
                  <img
                    className="home-r1-img cs-draggable-shapes"
                    src={HomeR1Img}
                    alt="BabyBera Shape"
                  />
                  <img
                    className="home-r2-img"
                    src={HomeR2Img}
                    alt="BabyBera Shape"
                  />
                  <img
                    className="home-r3-img"
                    src={HomeR3Img}
                    alt="BabyBera Shape"
                  />
                </div>
              </div>
            </section> */}

            <section className="bera-stats-section">
              <div className="box">
                <div className="bera-stats-content">
                  <div className="section-heading">
                    <div className="sh-box">
                      <h3>BABYBERA STATS</h3>
                      <div className="sh-line-blue"></div>
                      <div className="sh-line-pink"></div>
                    </div>
                  </div>
                  <div className="bera-stats-parent-box-con">
                    {/* <img
                      src={HomeFloatingBubble1Img}
                      className="home-floating-bubble-1"
                      alt=""
                    />
                    <img
                      src={HomeFloatingBubble2Img}
                      className="home-floating-bubble-2"
                      alt=""
                    />
                    <img
                      src={HomeFloatingBubble2Img}
                      className="home-floating-bubble-3"
                      alt=""
                    />
                    <img
                      src={HomeFloatingBubble4Img}
                      className="home-floating-bubble-4"
                      alt=""
                    /> */}
                    <div className="bera-stats-parent-box">
                      <div data-speed={1.15} className="bera-stats-number-box">
                        <p>Market Cap</p>
                        <h4>$406K</h4>
                      </div>
                      <div data-speed={1.25} className="bera-stats-number-box">
                        <p>Circulating Supply</p>
                        <h4>13M</h4>
                      </div>
                      <div data-speed={1.35} className="bera-stats-number-box">
                        <p>Price</p>
                        <h4>2.3M</h4>
                      </div>
                      <div data-speed={1.25} className="bera-stats-number-box">
                        <p>30 Day Volume</p>
                        <h4>$522M</h4>
                      </div>
                      <div data-speed={1.15} className="bera-stats-number-box">
                        <p>Total Burned</p>
                        <h4>321K</h4>
                      </div>
                    </div>
                  </div>

                  <div className="total-value-box">
                    <h6>Total Value Locked (TVL)</h6>
                    <h4>$1,944,260.88</h4>
                  </div>
                </div>
                <div className="home-left-shapes-box" data-speed={1.5}>
                  <img
                    className="home-l1-img cs-draggable-shapes"
                    src={HomeL1Img}
                    alt="BabyBera Shape"
                  />
                  <img
                    className="home-l2-img"
                    src={HomeL2Img}
                    alt="BabyBera Shape"
                  />
                </div>
                <div className="home-bl-shapes-box" data-speed={1.5}>
                  <img
                    className="home-bl1-img"
                    src={HomeBL1Img}
                    alt="BabyBera Shape"
                  />
                  <img
                    className="home-bl2-img cs-draggable-shapes"
                    src={HomeBL2Img}
                    alt="BabyBera Shape"
                  />
                </div>
              </div>
            </section>
          </div>
          <div id="dropArea"></div>
        </main>
      </div>
    </div>
  );
};

export default ComingSoon;
