import ComingSoon from "./views/ComingSoon";
import BeraHouses from "./views/BeraHouses";
import Bonds from "./views/Bonds";
import Home from "./views/Home";
import Farms from "./views/Farms";
import Mint from "./views/Mint";
import Stake from "./views/Stake";
import DiscordTroll from "./views/DiscordTroll";
import {
  BrowserRouter as Switch,
  Routes,
  Route,
  Router,
  useLocation,
} from "react-router-dom";
import MintNew from "./views/MintNew";
import StakeNew from "./views/StakeNew";

function App() {
  return (
    <>
      <Switch>
        <Routes>
          <Route exact path="/" element={<ComingSoon />} />
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/berahouses" element={<BeraHouses />} />
          <Route exact path="/bonds" element={<Bonds />} />
          <Route exact path="/farms" element={<Farms />} />
          <Route exact path="/mint" element={<Mint />} />
          <Route exact path="/stake" element={<Stake />} />
          <Route exact path="/mintnew" element={<MintNew />} />
          <Route exact path="/stakenew" element={<StakeNew />} />
          <Route exact path="/discord" element={<DiscordTroll />} />
        </Routes>
      </Switch>
    </>
  );
}

export default App;
